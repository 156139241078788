import { createContext, useContext, useState } from "react";

const colorThemeContext = createContext(null);

export const ColorThemeContextProvider = ({ children }) => {
  // const defaultDark = window.matchMedia &&
  // window.matchMedia("(prefers-color-scheme: dark").matches;
  const [theme, setTheme] = useState("dark");

  document.body.setAttribute("data-color-theme", theme);

  return (
    <colorThemeContext.Provider value={{ theme, setTheme }}>
      {children}
    </colorThemeContext.Provider>
  );
};

export const useColorTheme = () => {
  const context = useContext(colorThemeContext);
  if (!context) {
    throw new Error(
      "useColorTheme must be used with ColorThemeContextProvider",
    );
  }

  return context;
};
