export const getOS = () => {
    const userAgent = window.navigator.userAgent.toLowerCase();
    if(userAgent.indexOf('win') !== -1) return 'windows';
    if(userAgent.indexOf('mac') !== -1) return 'macos';
    if(userAgent.indexOf('linux') !== -1) return 'linux';
    if(userAgent.indexOf('android') !== -1) return 'android';
    if(userAgent.indexOf('iphone') !== -1 || userAgent.indexOf('ipad') !== -1) return 'ios';
    return 'Unknown OS';
}


