import { createEntityAdapter } from "@reduxjs/toolkit";
import { mosaicApi } from "./mosaicApiReducer";

// export const selectOrganizationsResult = mosaicApi.endpoints.getUserOrganizations.selectAllOrganizations;

const emptyBuildings = [];

// export const selectAllOrganizations = createSelector(
//     selectOrganizationsResult,
//     organizationResult => organizationResult?.data ?? emptyBuildings
// )

// export const selectOrganizationById = createSelector(
//     selectAllOrganizations,
//     (state, _id) => _id,
//     (organizations, _id) => organizations.find(org => org._id === _id)
// )

const organizationsAdapter = createEntityAdapter();
const initialState = organizationsAdapter.getInitialState();

export const organizationReducer = mosaicApi.injectEndpoints({
    endpoints: (build) => ({
        getUserOrganizations: build.query({
            query: () => "/organizations/",
            providesTags: (result, error, id) => [{ type: "Organization", id }],
        }),

        getUserCurrentOrganization: build.query({
            query: () => `/organizations/me/`,
            providesTags: ["Organization"],
            transformResponse: (response, meta, arg) => response,
        }),

        getOrganizationByOrgId: build.query({
            query: (id) => `/organizations/${id}`,
            providesTags: ["Organization"],
        }),

        getInvitationsForCurrentOrg: build.query({
            query: (id) => `/invites/`,
            // providesTags: ["Organization"],
        }),

        getWsAuth: build.query({
            query: (id) => `/organizations/ws/ws_jwt`,
             providesTags: ["Websocket"],
            // providesTags: ["Organization"],
        }),

        inviteUserToCurrentOrg: build.mutation({
            query: (emailAddr) => ({
                url: `/invites/invite`,
                method: "POST",
                body: { email: emailAddr, admin_access: true },
            }),
        }),

        members: build.query({
            query: () => '/organizations/members',
            providesTags: ["Members"],
        }),

        addNewOrganization: build.mutation({
            query: (orgName) => ({
                url: `/organizations/?name=${orgName}`,
                method: "POST",
                body: orgName,
            }),
            invalidatesTags: ["Organization", "User", "Project", "Activity", "Keys"],
        }),

        updateOrganization: build.mutation({
            query: ({ organization }) => ({
                url: `/organizations/`,
                method: "PUT",
                body: organization,
            }),
            TransformResponse: (response, meta, arg) => {
                return response.data;
            },
            transformErrorResponse: (response, meta, arg) => response.status,
            invalidatesTags: ["Organization", "Activity"],
        }),

        updateTimes: build.mutation({
            query: ({ organization }) => ({
                url: `/organizations/`,
                method: "PUT",
                body: organization,
            }),
            TransformResponse: (response, meta, arg) => {
                return response.data;
            },
            transformErrorResponse: (response, meta, arg) => response.status,
            invalidatesTags: ["Organization", "Activity"],
        }),
    }),
    overrideExisting: false,
});

export const {
    // base queries
    useGetUserOrganizationsQuery,
    useMembersQuery,
    useGetUserCurrentOrganizationQuery,
    useGetWsAuthQuery,
    useGetOrganizationByOrgIdQuery,
    useUpdateTimesQuery,
    // mutations
    useAddNewOrganizationMutation,
    useUpdateOrganizationMutation,
    // messing with different hooks
    useLazyGetOrganizationMembersQuery, 
    useInviteUserToCurrentOrgMutation

} = organizationReducer;
