import {
    useGetUserCurrentOrganizationQuery,
} from "../redux/MosaicApi/organizationReducer";
import {selectCurrentUser, selectCurrentOrg} from "../redux/MosaicApi/admin";
import {useSelector} from "react-redux";
import { useGetUserOrganizationsQuery } from "../redux/MosaicApi/organizationReducer";
import _ from "lodash";


//export function isTrialActive() {
export function useIsTrialActive() {
    const ourUser = useSelector(selectCurrentUser);

    const { currentData, data: organizations } = useGetUserOrganizationsQuery();
    const currentOrg = _.find(organizations, (org) => {
        if (org._id === ourUser?.current_organization_id) return org;

        return;
    });

    var is_trial = true;
    // var is_paid = false;
    // var organizationId = undefined;
    if (currentOrg) {
        if (currentOrg.is_paid_up) {
            // is_paid = true;
        }

        if (currentOrg.paid_product_name) {
            is_trial = false;
            // menu_title = "Subscription: " + currentOrg.paid_product_name;
            // if (!is_paid) {
            //     menu_title += " (invoice due)";
            // }
        }

        // organizationId = currentOrg._id;
    }

    return is_trial;
}
