import * as Tooltip from "@radix-ui/react-tooltip";
import moment from "moment";


export const RelativeDate = ({ when, prefix = "", nobr_ok=true }) => {
    const whenMoment = new moment(when);
    // console.log("whenMoment = ", whenMoment, whenMoment.fromNow());
    return (
        <Tooltip.Provider>
            <Tooltip.Root>
                <Tooltip.Trigger asChild>
                    {nobr_ok ? 
                        <nobr>
                            {prefix}
                            {whenMoment.isValid() ? whenMoment.fromNow() : <span style={{color: "#666666af"}}>&mdash;</span>}
                        </nobr>
                        :
                        <>
                            {prefix}
                            {whenMoment.isValid() ? whenMoment.fromNow() : <span style={{color: "#666666af"}}>&mdash;</span>}
                        </>
                    }
                </Tooltip.Trigger>
                <Tooltip.Portal>
                    <Tooltip.Content className="TooltipContent" sideOffset={5}>
                        {whenMoment.format("LLL")}
                        <Tooltip.Arrow className="TooltipArrow" />
                    </Tooltip.Content>
                </Tooltip.Portal>
            </Tooltip.Root>
        </Tooltip.Provider>
    );
};
