import React from "react";
import { ProjectFeedsTab } from "./projects/ProjectFeedsTab";


export const FeedsView = () => {
    // const uploads = useSelector(selectUploadsList());
    const feeds = [];

    return (<>
            <section className="resource">
                <span className="resource-section-label">Featrix Feeds<> ({feeds.length})</>
                </span>

                <div className="mt-12">
                    {/* FIXME: Need to adapt the project data uploads to do it unassociated -- there are already options to not-associate there, so just need to clean it up */}
                    {/*<button*/}
                    {/*    type="submit"*/}
                    {/*    onClick={() =>*/}
                    {/*        navigate(`/projects/${myProject._id}/datasources/create`)*/}
                    {/*    }*/}
                    {/*>*/}
                    {/*    Connect New Data*/}
                    {/*</button>*/}

                </div>

                <ProjectFeedsTab/>
            </section>
        </>)
};

/*
{
    path: "feeds",
    element: <ProjectFeedsTab/>
},
*/