import React from "react";
import { useSelector } from "react-redux";
import { selectUploadsList } from "../redux/NeuralApi/uploadMapReducer";
import { ProjectDataFile } from "./ProjectDataFile";


export const LibraryView = () => {
    const uploads = useSelector(selectUploadsList());

    return (<>
            <section className="resource">
                <span className="resource-section-label">Library of Data Sources{
                    uploads ? <> ({uploads.length})</> : <></>}
                </span>

                <div className="mt-12">
                    {/* FIXME: Need to adapt the project data uploads to do it unassociated -- there are already options to not-associate there, so just need to clean it up */}
                    {/*<button*/}
                    {/*    type="submit"*/}
                    {/*    onClick={() =>*/}
                    {/*        navigate(`/projects/${myProject._id}/datasources/create`)*/}
                    {/*    }*/}
                    {/*>*/}
                    {/*    Connect New Data*/}
                    {/*</button>*/}

                </div>

                <div className="mt-24">
                    {uploads.length === 0 ? (
                            <>
                                <p style={{paddingTop: "2px", paddingLeft: "48px"}}>No data sources in library yet.</p>
                                <p style={{paddingTop: "2px", paddingLeft: "48px"}}>Import in Project screen.</p>
                            </>
                    ) : (
                        <></>
                    )}
                    {uploads.map((upload, idx) => (<ProjectDataFile key={upload._id} targetUploadId={upload._id}/>))}
                </div>
            </section>
        </>)
};
