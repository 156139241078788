import React, { useState } from "react";
 import { useNavigate } from "react-router-dom";
 // redux
 import { useAddNewOrganizationMutation } from "../../redux/MosaicApi/organizationReducer";

 export const CreateOrganizationView = () => {
   // const { currentData, data: organizations, isFetching } = useGetUserOrganizationsQuery("root"); --> remember to import this if that's what we want to do!

   // TLDR: see an implementation of retrieving cached data in ../organizations/OrganizationMembersTab.jsx

   // we could subscribe this component to show things like "recently made orgs"
   // or to see if they're re-using a name (if that's something we want to avoid)

   const [addNewOrganization, { isLoading }] = useAddNewOrganizationMutation();
   const [newOrgRecord, setNewOrgRecord] = useState({
     // There may be more fields we want users to provide - this implementation keeps our options open and easy for devs to adjust
     name: "",
   });
   const navigate = useNavigate();

   const handleInputChange = (event) => {
     event.preventDefault();
     setNewOrgRecord({
       ...newOrgRecord,
       [event.currentTarget.name]: event.currentTarget.value,
     });
   };

   const canSave = [newOrgRecord.name].every(Boolean) && !isLoading;

   const handleSubmit = async (event) => {
     event.preventDefault();
     if (canSave) {
       try {
         await addNewOrganization(newOrgRecord.name)
           .unwrap()
           .then((payload) => navigate(`/organizations/${payload._id}`));
         setNewOrgRecord({
           name: "",
         });
       } catch (error) {
        console.error("Failed to save organization: ", error);
       }
     }
   };

  return (
      <div className="full-width">
        <h3 className="center">Create New Organization</h3>
        <form onSubmit={handleSubmit}>
          <label htmlFor="name">
            <input
                id="name"
                type="text"
                name="name"
                value={newOrgRecord.name}
                onChange={handleInputChange}
                placeholder="My Organization"
                size="30"
            />
          </label>
          <div style={{marginTop: "50px"}}>
            <button disabled={!canSave} type="submit">
              Create
            </button>
          </div>
        </form>
      </div>
  );
 };
