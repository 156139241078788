import EmailPassword from "supertokens-auth-react/recipe/emailpassword";
import { EmailPasswordPreBuiltUI } from "supertokens-auth-react/recipe/emailpassword/prebuiltui";
import Session from "supertokens-auth-react/recipe/session";
import EmailVerification from "supertokens-auth-react/recipe/emailverification";
import { EmailVerificationPreBuiltUI } from "supertokens-auth-react/recipe/emailverification/prebuiltui";

/* NOTE: Can't use dbg in here right now, since we use the production to eval it */


export function getApiDomain(neural = false) {
    // Note that if you set REACT_APP_API_URL, we ignore the REACT_APP_API_PORT
    const apiPort = process.env.REACT_APP_API_PORT || 3001;
    let url = process.env.REACT_APP_API_URL || `http://localhost:${apiPort}`;
    let suffix = neural ? "api/neural" : "api";
    return `${url}/${suffix}`;
}

export function getWebsiteDomain() {
    // Note that if you set REACT_APP_WEBSITE_URL, we ignore REACT_APP_WEBSITE_API_PORT
    const websitePort = process.env.REACT_APP_WEBSITE_PORT || 3000;
    return process.env.REACT_APP_WEBSITE_URL || `http://localhost:${websitePort}`;
}

export function isProduction() {
    // console.log("window.location = ", window.location, typeof(window.location));
    if (window.location.href.startsWith("https://app.featrix.com")) {
        return true;
    }

    return false;
}

const emailPasswordConfig = {
    useShadowDom: false,          // Turning this off enables password managers to work, so says SuperTokens docs.
    signInAndUpFeature: {
        signUpForm: {
            formFields: [
                {
                    id: "first_name",
                    label: "First name",
                    placeholder: "First name",
                },
                {
                    id: "last_name",
                    label: "Last Name",
                    placeholder: "Last name",
                },
                {
                    id: "company",
                    label: "Company",
                    optional: true,
                    placeholder: "Optional",
                },
                {
                    id: "organization_id",
                    label: "",
                    inputComponent: ({ value, name, onChange }) => <div></div>,
                    optional: true,
                },
            ],
        },
    },
    style: `
    [data-supertokens~=secondaryText] {
      color: var(--featrix-logo-color);
    }

    [data-supertokens~=container] {
      background-color: rgba(0, 0, 0, 0.5);
      border: 2px solid var(--featrix-border-color);
    }

    [data-supertokens$=Title] {
      color: var(--featrix-headline-text-color);
    }

    [data-supertokens~=label],
    [data-supertokens$=Text] {
      color: var(--featrix-text-color);
    }

    [data-supertokens=input] {
      background-color: white;
    }

    [data-supertokens~=button] {
      color: var(--featrix-button-text-color);
      border-color: var(--featrix-button-border-color);
      background-color: var(--featrix-button-bg-color);
      height: 40px;
    }

    [data-supertokens~=button]:hover {
      color: black;
      background-color: var(--featrix-button-text-color);
    }

    [data-supertokens=superTokensBranding] {
      color: black;
      margin-bottom: 1rem;
      border-radius: 4px;
    }

    [data-supertokens=divider] {
      border-color: var(--featrix-highlight-color);
    }

    [data-supertokens=link] {
      color: var(--featrix-logo-color);
      font-weight: 600;
    }
  `,
};
const emailVerificationConfig = {
    mode: "REQUIRED",
    style: `
                [data-supertokens~=link] {
                    border: 2px solid #0076ff;
                    border-radius: 5;
                    width: 30%;
                    margin: 0 auto;
                }
            `,
};
// let recipeList;
// if(process.env.FEATRIX_EMAIL_VERIFICATION === "true") {
//   recipeList = [
//     EmailPassword.init(emailPasswordConfig),
//     EmailVerification.init(emailVerificationConfig),
//     Session.init()
//   ]
// } else {
//   recipeList = [
//     EmailPassword.init(emailPasswordConfig),
//     Session.init()
//   ]
// }

export const SuperTokensConfig = {
    appInfo: {
        appName: "Featrix Client Portal",
        apiDomain: getApiDomain(),
        websiteDomain: getWebsiteDomain(),
        apiBasePath: "/api/auth",
        websiteBasePath: "/auth"
    },
    // recipeList contains all the modules that you want to
    // use from SuperTokens. See the full list here: https://supertokens.com/docs/guides
    //recipeList: [EmailPassword.init(), Session.init()],

    // FIXME: need to add in socials but need to add in the extra fields too?
    recipeList: [
        EmailPassword.init(emailPasswordConfig),
        EmailVerification.init(emailVerificationConfig),
        Session.init(),
    ],
};

export const recipeDetails = {
    docsLink: "https://supertokens.com/docs/emailpassword/introduction",
};

export const PreBuiltUIList = [
    EmailPasswordPreBuiltUI,
    EmailVerificationPreBuiltUI,
];
